import Services from "../../../services"

class CustomerApi {
  get() {
    return Services.get("/mastercustomer")
  }

  getPage(page, dataLength, key) {
    return Services.get(`/mastercustomer/page/?per_page=${dataLength}&page=${page}&q=${key}`)
  }

  no_baru(params) {
    return Services.get("/mastercustomer/no_baru", { params })
  }

  getJenis() {
    return Services.get("/jeniscustomer/dropdown")
  }

  getProvinsi() {
    return Services.get("/wilayah/dropdown_provinsi")
  }

  getKabupaten(id) {
    return Services.get("/wilayah/dropdown_kabupaten?id_provinsi=" + id)
  }

  getKecamatan(id) {
    return Services.get("/wilayah/dropdown_kecamatan?id_kabupaten=" + id)
  }

  getDesa(id) {
    return Services.get("/wilayah/dropdown_desa?id_kecamatan=" + id)
  }

  getBank() {
    return Services.get("/bank/dropdown")
  }
  search(key) {
    return Services.get("/mastercustomer/page/?per_page=10&page=1&q=" + key)
  }

  show(value) {
    return Services.put("/mastercustomer/show", value)
  }

  hide(value) {
    return Services.put("/mastercustomer/hide", value)
  }

  create(value) {
    return Services.post("/mastercustomer", value)
  }

  update(value) {
    return Services.put("/mastercustomer", value)
  }

  delete(value) {
    return Services.post("/mastercustomer/delete", value)
  }

  uploadKTP(value) {
    return Services.post("/upload/ktp", value)
  }

  uploadNPWP(value) {
    return Services.post("/upload/npwp", value)
  }

  single(params) {
    return Services.get("/mastercustomer/single", { params })
  }
}

export default new CustomerApi()

import Services from "../../../services";

class JenisCustomerApi {
  get() {
    return Services.get("/jeniscustomer");
  }

  getPage(page, dataLength, key) {
    return Services.get(`/jeniscustomer/page/?per_page=${dataLength}&page=${page}&q=${key}`);
  }

  single(params) {
    return Services.get('/jeniscustomer/single', { params })
  }

  getKode() {
    return Services.get("/jeniscustomer/no_baru");
  }

  search(key) {
    return Services.get("/jeniscustomer/page/?per_page=10&page=1&q=" + key);
  }

  show(value) {
    return Services.put("/jeniscustomer/show", value);
  }

  hide(value) {
    return Services.put("/jeniscustomer/hide", value);
  }

  create(value) {
    return Services.post("/jeniscustomer", value);
  }

  update(value) {
    return Services.put("/jeniscustomer", value);
  }

  delete(value) {
    return Services.post("/jeniscustomer/delete", value);
  }
}

export default new JenisCustomerApi();

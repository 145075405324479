import React from 'react'

const Tr = ({children}) => {
  return (
    <tr>
      {children}
    </tr>
  )
}

export default Tr
